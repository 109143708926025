<template>
  <Popup
    title="시스템 로그 상세"
    :maxWidth="1000"
    closeBtnText="닫기"
    @onClickClose="$emit('onClickSystemDetailPopupClose')">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col />
        <col style="width:140px"/>
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>id</th>
          <td>{{ detailData.id }}</td>
          <th>uid</th>
          <td>{{ detailData.uid }}</td>
        </tr>
        <tr>
          <th>category</th>
          <td colspan="3">{{ detailData.category }}</td>
        </tr>
        <tr>
          <th>createdAt</th>
          <td>{{ detailData.createdAt }}</td>
          <th>createdBy</th>
          <td>{{ detailData.createdBy }}</td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col />
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">request</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>requestAt</th>
          <td>{{ detailData.requestAt | convertDateFormat() }}</td>
        </tr>
        <tr>
          <th>requestIp</th>
          <td><span class="txt_tbl" v-html="detailData.requestIp.replaceAll(',' , ',<br>')"></span></td>
        </tr>
        <tr>
          <th>requestUri</th>
          <td>{{ detailData.requestUri }}</td>
        </tr>
        <tr>
          <th>requestHeader</th>
          <td><span class="txt_tbl" v-html="detailData.requestHeader.replaceAll(',' , ',<br>')"></span></td>
        </tr>
        <tr>
          <th>requestBody</th>
          <!-- <td><span class="txt_tbl txt_tree" v-html="toTree(detailData.requestBody)"></span></td> -->
          <td><pre>{{ JSON.stringify(JSON.parse(detailData.requestBody),null,2) }}</pre></td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col />
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">response</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>responseAt</th>
          <td>{{ detailData.responseAt }}</td>
        </tr>
        <tr>
          <th>responseStatus</th>
          <td>{{ detailData.responseStatus }}</td>
        </tr>
        <tr>
          <th>responseHeader</th>
          <td><span class="txt_tbl" v-html="detailData.responseHeader.replaceAll(',' , ',<br>')"></span></td>
        </tr>
        <tr>
          <th>responseBody</th>
          <td><pre>{{ JSON.stringify(JSON.parse(detailData.responseBody),null,2) }}</pre></td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:140px"/>
        <col />
        <col style="width:140px"/>
        <col />
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">updated</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>updatedAt</th>
          <td>{{ detailData.updatedAt }}</td>
          <th>updatedBy</th>
          <td>{{ detailData.updatedBy }}</td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';


export default {
  name:'OrderDetailPopup',
  components:{
    Popup,
    TableView,
    Board,
  },
  props:{
    detailData: Object,
  },
  data(){
    return{
      testData : '{"header":{"request_at":"2022-11-15 15:35:43","response_at":"2022-11-15 15:35:43","result":{"request_uri":"/api/money/v1/send-money","request_method":"POST","code":"200","message":"성공","description":"성공"},"notice":{"is_visibility":false,"type":null,"message":null}},"pagination":null,"sort":null,"encrypt_body":"SHHfETQ3H4YfofDlHpMeyA==","body":null}'
    }
  },
  methods:{
    toTree(data) {
      data = JSON.parse(data).toString;
      return data.replaceAll('{','{<div>')
      .replaceAll('}','</div>}')
      .replaceAll(',' , ',<br>')
    },
  }
}
</script>

<style lang="scss" scoped>
.tbl_view .txt_tbl{word-break:break-all}
.tbl_view tbody td pre{line-height:18px}
.tbl_view tbody td .txt_tree{display:block}
.tbl_view tbody td .txt_tree ::v-deep div{padding-left:20px}
</style>